<template>
  <div id="app" style="min-height: 100vh; background-color: rgba(253, 180, 165, 1)">
    <div v-if="type === 0" style="height: 100%;">
      <img src="../../assets/images/guangfa3500/bg_guangfa3500.png" alt=""
           style="width: 100%; display: block;"/>
      <img src="../../assets/images/guangfa3500/bg_guangfa3500_recharge.png" alt=""
           style="width: 100%; display: block;" @click="onClickToRecharge()"/>
    </div>

    <div v-if="type === 1">
      <img src="../../assets/images/guangfa3500/bg_whitelist.jpg" alt=""
           style="width: 100%;"/>
    </div>

    <div v-if="type === 2" style="height: 100%;">
      <img src="../../assets/images/guangfa3500/bg_guangfa3500_bgtake.png" alt=""
           style="width: 100%; display: block;"/>
      <img src="../../assets/images/guangfa3500/bg_guangfa3500_take.png" alt=""
           style="width: 100%; display: block;" @click="onClickToTake()"/>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {post} from '@/utils/http'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {Dialog, Toast} from 'vant';
import Notify from "vant/es/notify";
import {isIphoneX} from '@/utils/util'

export default {
  name: "guangfa3500",
  data() {
    return {
      loading: false,
      customerUser: null,
      type: 0,
      paymentInfo: null,
      customerAccount: null,
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
    if (this.customerUser) {
      this.loadBusinessPaymentInfo();

      let whiteList = [
        "017ba08021803e9d2c96c1f37b973694", // 三龙湾小学	陈永增	13702554720	三年级4班	陈秋宇
      ];
      let whiteListSet = [
        "017ba08021803e9d2c96c1f37b973694", // 三龙湾小学	陈永增	13702554720	三年级4班	陈秋宇
      ];

      // TODO:判断是否充值了3500
      if (whiteList.findIndex(id => id === this.customerUser.businessCustomerUserId) > -1) {
        // TODO:判断是否设置了白名单
        if (whiteListSet.findIndex(id => id === this.customerUser.businessCustomerUserId) > -1) {
          this.type = 2;
        } else {
          this.type = 1;
        }
      }
    }

    this.isIphoneX();
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goBack() {
      this.$router.push('/index');
      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    onClickToRecharge() {
      if (!this.customerUser) {
        Dialog.alert({message: '未登录，请先登录再参与活动哦~'}).then(() => {
          this.$router.push({path: '/index'})
        });
        return;
      }
      if (!this.paymentInfo || this.paymentInfo.code !== "GF") {
        Dialog.alert({message: '您绑定的消费者学校暂不符合活动条件~请重新选择消费者'}).then(() => {
        });
        return;
      }
      if (!this.isGFSchool()) {
        Dialog.alert({message: '您绑定的消费者学校暂不符合活动条件~请重新选择消费者！'}).then(() => {
        });
        return;
      }

      this.loading = true;
      post(
          "api/customer/account/v2/:businessCustomerUserId/find".replace(
              ":businessCustomerUserId",
              this.customerUser.businessCustomerUserId
          ),
          {},
          true
      )
          .then((res) => {
            this.customerAccount = res.data;

            if (!this.customerAccount.id) {
              this.$router.push({path: "/AccountAgreement"});
              return;
            } else if (!this.checkAccountState()) {
              return;
            }

            this.$router.push({path: "/recharge"});

            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });

    },
    onClickToTake() {
      window.location.href = 'http://d.yesm.cn/TPKx00';
    },

    loadBusinessPaymentInfo() {
      post(
          "api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace(
              "{businessId}",
              this.customerUser.businessId
          )
      )
          .then((result) => {
            this.paymentInfo = result.data;
          })
          .catch((error) => {
            console.log(error.message);
          });
    },

    checkAccountState() {
      if (this.customerAccount.auditStatus === "04") {
        // 二审失败
        this.$router.push({
          path: "/AccountCreateFeedback",
          query: {
            type: "UPGRADE_FAIL",
            msg: this.customerAccount.auditRemark,
          },
        });
        return false;
      } else if (this.customerAccount.auditStatus === "05") {
        // 等待影相再审核
        this.$router.push({
          path: "/AccountCreateFeedback",
          query: {type: "ID_CARD_REVIEWING"},
        });
        return false;
      }
      return true;
    },

    isIphoneX() {
      console.log(isIphoneX())
      return isIphoneX();
    },

    isGFSchool() {
      if (!this.customerUser) {
        return false;
      }

      let businessId = this.customerUser.businessCustomerUser.business.id;
      // 深村小学、石湾一小、三龙湾小学、紫南小学、铁军小学、黎涌小学、冼可澄纪念小学、保利天珀幼儿园、惠雅幼儿园
      // 一天膳事测试学校
      if (
          businessId === '017c0b4f337b06b72c96c1f37bfa026a' ||
          businessId === '017c124eaa6c14f42c96c1f37c0e1ec6' ||
          // businessId === '017b9b99ec221ad92c96c1f37b973694' ||
          businessId === '017c1096128a03a82c96c1f37c0e1ec6' ||
          businessId === '017be2d1e83a68ca2c96c1f37bdfc369' ||
          businessId === '017ba96e9316061c2c96c1f37ba23995' ||
          businessId === '017c7275f66e01bf2c96c1f37c70498f' ||
          // businessId === '018266eaad4c26082c96c1f382573a7f' ||
          // businessId === '01821afe2ad029852c96c1f3820a96e9' ||
          businessId === '017b97396de9000a2c96c1f37b973694') {
        return true;
      }
      return false;
    },
  }
}
</script>

<style scoped>

</style>